// import axios from 'axios';




// // ฟังก์ชันเพิ่มรีวิว
// export const addReview = async (authtoken,productId, rating, comment, ) => {
//   try {
//     const response = await axios.post(process.env.REACT_APP_API + '/addreviews', 
//       { 
//         productId, 
//         rating, 
//         comment 
//       }, 
//       { 
//         headers: { 
//           authtoken,
//         }
//       }
//     );
//     return response.data; // ส่งข้อมูลรีวิวที่เพิ่ม
//   } catch (error) {
//     console.error("Error adding review:", error);
//     throw error; // โยนข้อผิดพลาดให้ในที่ที่เรียกใช้งาน
//   }
// };

import axios from "axios";



export const addReview = async (productId, rating, comment) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/review`,
      { productId, rating, comment }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding review:", error);
    throw error;
  }
};


export const getProductReviews = async (productId) => {
  return await axios.get(process.env.REACT_APP_API + "/reviews/" + productId);
};

