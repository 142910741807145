import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ProductCard from "../card/ProductCard";
import { listProduct, searchFilters } from "../function/product";
import { listCategory } from "../function/category";
import { listCategoryService } from "../function/categoryservice"; // ใช้สำหรับสินค้าบริการ
import { useLocation } from "react-router-dom";
import { Slider, Select } from "antd";
import Footer from "../layouts/Footer";
import "./Shop.css";

const { Option } = Select;

const ShopOrigi = () => {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState([0, 0]);
  const [ok, setOk] = useState(false);
  const [category, setCategory] = useState([]);
  const [categorySelect, setCategorySelect] = useState([]);
  const [categoryType, setCategoryType] = useState("");

  const { search } = useSelector((state) => ({ ...state }));
  const { text } = search;

  const location = useLocation();

  // ฟังก์ชันโหลดข้อมูลสินค้า
  const loadData = () => {
    setLoading(true);
    listProduct(100)
      .then((res) => {
        setProduct(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const fetchDataFilter = (arg) => {
    searchFilters(arg).then((res) => {
      setProduct(res.data);
    });
  };

  // ตรวจสอบ query parameters เมื่อ URL เปลี่ยน
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryFromUrl = params.get("category");
    const categoryTypeFromUrl = params.get("categoryType");

    if (categoryTypeFromUrl && categoryTypeFromUrl === "360") {
      listCategory().then((res) => {
        setCategory(res.data);

        // ตรวจสอบหมวดหมู่ที่ได้รับจาก URL และกรองสินค้าตาม ID
        const categoryIds = {
          'supplement-for-health': '666cfc1691472649211207f5',
          'beauty-supplements': '666d097b25462c519c2685fe',
          'personal-care-hygiene': '666d205a25462c519c268653',
          'organic-natural-products': '66f7a06eacfb993d2ef594a4',
          'fitness-performance': 'another-id-4',
          'home-living': 'another-id-5',
          'health-wellness': 'another-id-6'
        };

        // หากหมวดหมู่ตรงกับใน `categoryIds` ให้กรองสินค้า
        const selectedCategoryId = categoryIds[categoryFromUrl];
        if (selectedCategoryId) {
          setCategorySelect([selectedCategoryId]);
          fetchDataFilter({ category: selectedCategoryId });
        }
      });
    } else if (categoryTypeFromUrl === "service") {
      // ดึงข้อมูลหมวดหมู่สินค้าบริการ
      listCategoryService().then((res) => {
        setCategory(res.data);

        const categoryIds = {
          rehabilitation: "66c5a9fff9f03b44e0978a2b",
          medicalspa: "66cd5ade24b0335c5300dc8c",
          aesthetics: "66c5934ef9f03b44e0978625",
          "anti-aging": "66c5934ef9f03b44e0978625",
          "hotels-booking": "66c5934ef9f03b44e0978625",
        };

        const selectedCategoryId = categoryIds[categoryFromUrl];
        if (selectedCategoryId) {
          setCategorySelect([selectedCategoryId]);
          fetchDataFilter({ category: selectedCategoryId });
        }
      });
    } else {
      // กรณีไม่มี `categoryType` ที่ตรงกัน
      console.log("Invalid category type");
    }
  }, [location.search]);

  // ฟังก์ชันกรองสินค้าตามราคาที่เลือก
  useEffect(() => {
    fetchDataFilter({ price });
  }, [ok]);

  // เมื่อหมวดหมู่เปลี่ยนแปลงให้ทำการกรองข้อมูล
  const handleCategoryChange = (value) => {
    setCategorySelect(value);
    if (value.length > 0) {
      fetchDataFilter({ category: value });
    } else {
      loadData();
    }
  };

  const handlePrice = (value) => {
    setPrice(value);
    setTimeout(() => {
      setOk(!ok);
    }, 300);
  };

  const handleCategoryTypeChange = (value) => {
    setCategoryType(value);
    if (value === "360") {
      listCategory().then((res) => setCategory(res.data));
    } else if (value === "service") {
      listCategoryService().then((res) => setCategory(res.data));
    }
    setCategorySelect([]); // Reset the selected category
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="row mt-1">
          <div
            className="col-md-2"
            style={{ backgroundColor: "#e1f8cd33", padding: "10px" }}
          >
            Filter / Search
            <hr />
            <h4>ค้นหาด้วยราคา</h4>
            <Slider value={price} onChange={handlePrice} range max={50000} />
            <hr />
            <h4>ประเภทหมวดหมู่</h4>
            <Select
              style={{ width: "100%" }}
              value={categoryType}
              onChange={handleCategoryTypeChange}
            >
              <Option value="360">หมวดหมู่ของสินค้า 360</Option>
              <Option value="service">หมวดหมู่สินค้าบริการ</Option>
            </Select>
            <hr />
            <h4>ค้นหาตามหมวดหมู่</h4>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              value={categorySelect}
              onChange={handleCategoryChange}
            >
              {category.map((item) => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="col-md-9 d-flex flex-column justify-content-start align-items-center align-self-start mt-5">
            {loading ? (
              <h4 className="text-danger">Loading...</h4>
            ) : (
              <h4 className="text-info">Product</h4>
            )}

            {product.length < 1 && <p>No Product found</p>}

            <div
              className={`row pb-1 ${
                product.length <= 3 ? "justify-content-center" : ""
              }`}
            >
              {product.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    product.length <= 1
                      ? "col-12 mx-auto"
                      : "col-10 col-md-3 mx-auto"
                  } mb-4`}
                >
                  <ProductCard product={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopOrigi;
