import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, Nav, NavDropdown, Badge } from "react-bootstrap";
import {
  HomeOutlined,
  UserAddOutlined,
  LoginOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  ScheduleOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import Search from "../card/Search";
import "./NavbarHead.css";

import logo from "./img/logo-header.png";

const NavBarHead = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, cart } = useSelector((state) => ({ ...state }));

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    navigate("/");
  };

  return (
    <Navbar className="custom-navbar" expand="lg" variant="light">
      {/* ช่องค้นหาอยู่ทางซ้ายสุด */}
      <div className="d-flex align-items-center flex-grow-1">
        <Search />
      </div>

      {/* โลโก้ตรงกลาง */}
      <Navbar.Brand as={Link} to="/" className="mx-auto">
        <img
          src={logo}
          alt="Logo"
          style={{
            width: "400px",
            height: "80px",
          }}
        />
      </Navbar.Brand>

      {/* เมนูขวาสุด */}
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto d-flex align-items-center">
          {/* เมนูผู้ใช้และการออกจากระบบ */}
          {user ? (
            <NavDropdown
              title={user.username}
              id="basic-nav-dropdown"
              className="user-dropdown"
            >
              <NavDropdown.Item
                as={Link}
                to={user.role === "admin" ? "/admin/index" : "/user/index"}
              >
                Dashboard
              </NavDropdown.Item>
              <NavDropdown.Item onClick={logout}>
                <LogoutOutlined /> Logout
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <>
              <Nav.Link as={Link} to="/login" className="flex-fill text-center">
                <LoginOutlined /> Login
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/register"
                className="flex-fill text-center"
              >
                <UserAddOutlined /> Register
              </Nav.Link>
            </>
          )}

          {/* ตะกร้าสินค้า */}
          <Nav.Link as={Link} to="/cart" className="flex-fill text-center">
            <ShoppingCartOutlined />
            <Badge pill bg="secondary" className="ms-1">
              {cart.length}
            </Badge>
            ตะกร้าสินค้า
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBarHead;
