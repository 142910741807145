import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

const ResponsiveNavbar = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">Home</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link as={Link} to="/shop-product" >
         Product
      </Nav.Link>
          <Nav.Link as={Link} to="/shop-services" >
         Services
      </Nav.Link>
            {/* <Nav.Link href="#groceries">Services</Nav.Link> */}
            <Nav.Link href="#groceries">Hot Deals</Nav.Link>
            <Nav.Link href="#health">Best Sellers</Nav.Link>
            <Nav.Link href="#vitamins">New Arrivals</Nav.Link>
            <Nav.Link href="#vitamins">Brands</Nav.Link>
            {/* <Nav.Link href="#mom-baby">Mom & Baby</Nav.Link>
            <Nav.Link href="#medical">Medical and Ambulantory Supplies</Nav.Link>
            <Nav.Link href="#beauty">Beauty Product</Nav.Link>
            <Nav.Link href="#organic">Organic Product</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ResponsiveNavbar;
