import React, { useState, useEffect } from "react";
import { listProductByCategoryService } from "../function/categoryservice"; // นำเข้าฟังก์ชันใหม่ที่ดึงสินค้าจากหมวดหมู่
import ProductCardHome from "../card/ProductCardHome";
import LoadingCard from "../card/LoadingCard";
import "./YooEnCare.css";

const Health = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  // กำหนด ID ของหมวดหมู่สินค้าหลายๆ ตัว
  const categoryIds = [
    "666d097b25462c519c2685fe", 
    // "66c5934ef9f03b44e0978625", 
    // "666d205a25462c519c268653" ,
    "673eb4ec79ca2e463fc91d80" ,
    // "673eb4ec79ca2e463fc91d80" ,
    

  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);

    // ใช้ Promise.all เพื่อดึงข้อมูลจากหลายหมวดหมู่พร้อมกัน
    const promises = categoryIds.map((categoryId) =>
      listProductByCategoryService(categoryId, 6)
    );

    Promise.all(promises)
      .then((responses) => {
        // รวมข้อมูลทั้งหมดจากแต่ละหมวดหมู่
        const allProducts = responses.flatMap((response) => response.data);
        setProducts(allProducts);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="container youencare-container">
      {loading ? (
        <LoadingCard count={3} />
      ) : (
        <div className="horizontal-scroll-container">
          <div className="horizontal-scroll">
          {products.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    products.length <= 3
                      ? "col-5 mx-auto"
                      : "col-5 col-lg-1 mx-auto"
                  } mb-4`}
                >
                  <ProductCardHome product={item} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Health;
