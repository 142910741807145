import React, { useEffect, useState } from "react";
import { Card, Tabs, Rate, Button, Input, message,List } from "antd";
import { HeartOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useSelector, useDispatch } from "react-redux";
import { addToWishlist } from "../function/users";
import { toast } from "react-toastify";
import _ from "lodash";
import Footer from "../layouts/Footer";
import { listPromotions } from "../function/promotion";
import "./SingleProductCard.css"; // นำเข้าไฟล์ CSS ของคุณ
import { addReview, getProductReviews } from "../function/review";


const { TextArea } = Input;



const { Meta } = Card;
const { TabPane } = Tabs;

const SingleProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({ ...state }));
  const { _id, title, description, images, price, sold, quantity, category } = product;
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [promotionDates, setPromotionDates] = useState({ startDate: null, endDate: null });
  const [promotionTitle, setPromotionTitle] = useState("");
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const res = await listPromotions();
        const promotions = res.data;
        const productPromotion = promotions.find(promotion =>
          promotion.products.some(p => p._id === product._id)
        );

        if (productPromotion) {
          const discount = productPromotion.discount;
          const discounted = product.price - (product.price * discount / 100);
          setDiscountedPrice(discounted);
          setPromotionDates({
            startDate: new Date(productPromotion.startDate).toLocaleDateString(),
            endDate: new Date(productPromotion.endDate).toLocaleDateString(),
          });
          setPromotionTitle(productPromotion.title);
        } else {
          setPromotionTitle("");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPromotions();
  }, [product]);

  useEffect(() => {
    if (product._id) {
      const fetchReviews = async () => {
        try {
          const res = await getProductReviews(product._id); // ส่ง _id ของสินค้า
          setReviews(res.data);
        } catch (error) {
          console.log(error);
        }
      };
  
      fetchReviews();
    } else {
      console.log("product._id is undefined");
    }
  }, [product._id]);
  
  

  const handleAddToCart = () => {
    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }
    cart.push({
      ...product,
      count: 1,
      discountedPrice: discountedPrice !== null ? discountedPrice : product.price,
    });

    let unique = _.uniqWith(cart, _.isEqual);
    localStorage.setItem("cart", JSON.stringify(unique));
    dispatch({
      type: "ADD_TO_CART",
      payload: unique,
    });
    dispatch({
      type: "SET_VISIBLE",
      payload: true,
    });
  };

  const handleAddToWishList = (e) => {
    if (user) {
      addToWishlist(user.token, _id)
        .then((res) => {
          toast.success(" เพิ่มลงในรายการที่ชื่นชอบ เรียบร้อย ");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("!! กรุณาลงชื่อเข้าใช้ !!");
    }
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault(); // Prevent the form from reloading the page
    
    if (!rating || !comment) {
      message.error("Please provide both rating and comment.");
      return;
    }
  
    // ตรวจสอบค่าที่ส่งไป
    console.log("Product ID:", product._id);
    console.log("Rating:", rating);
    console.log("Comment:", comment);
  
    try {
      const res = await addReview(product._id, rating, comment);
      message.success("Review added successfully!");
      setRating(0);
      setComment("");
      
      // รีเฟรชรีวิวหลังจากที่เพิ่มเสร็จ
      const reviewsRes = await getProductReviews(product._id);
      setReviews(reviewsRes.data);
    } catch (error) {
      console.error("Failed to add review:", error);
      message.error("Failed to add review.");
    }
  };
  
  



  return (
    <>
      {/* <div className="container single-product-container"> */}
      <div className="container mt-1">
        <div className="row justify-content-center align-items mb-5 mt-5">
          <div className="card-tap col-md-3">
            <Carousel autoPlay showArrows={true} infiniteLoop showThumbs={true}>
              {images &&
                images.map((item) => (
                  <div className="carousel-img-container" key={item.public_id}>
                    {console.log(item.url)} {/* ตรวจสอบ URL ของภาพ */}
                    <img src={item.url} className="carousel-img" />
                  </div>
                ))}
            </Carousel>
            <Tabs>
              <TabPane tab="Description" key="1">
              <div dangerouslySetInnerHTML={{ __html: description }} />
              </TabPane>
              <TabPane tab="More..." key="2">
                More...
              </TabPane>
            </Tabs>
          </div>
          {/* <div className="col-1"></div> */}
          <div className="card-tap-price col-md-3">
            <h2 className="p-2 mt-0" style={{ backgroundColor: "#eb6472", color: "#fff" }}>
              {discountedPrice !== null ? promotionTitle : title}
            </h2>
            <Card
              actions={[
                <a onClick={handleAddToWishList}>
                  <HeartOutlined className="text-info" />
                  <br />
                  Add to wishlist
                </a>,
                <a onClick={handleAddToCart}>
                  <ShoppingCartOutlined className="text-danger" />
                  <br />
                  Add to cart
                </a>,
              ]}
            >
              <ul className="list-group list-group-flush mt-1">
                <li className="list-group-item">
                  Price
                  <span className="float-end">
                    {discountedPrice !== null && discountedPrice !== undefined ? (
                      <>
                        <span className="original-price">{price.toFixed(2)}</span>{" "}
                        <span className="discounted-price">{discountedPrice.toFixed(2)}</span>
                      </>
                    ) : (
                      price !== null && price !== undefined ?
                        price.toFixed(2) : "N/A"
                    )}
                  </span>
                </li>
                <li className="list-group-item">
                  Quantity
                  <span className="float-end">{quantity}</span>
                </li>
                <li className="list-group-item">
                  Sold
                  <span className="float-end">{sold}</span>
                </li>
                {product.category && (
                  <li className="list-group-item">
                    Category
                    <span className="float-end">{category.name}</span>
                  </li>
                )}
                {promotionDates.startDate && (
                  <li className="list-group-item">
                    Promotion Start Date
                    <span className="float-end">{promotionDates.startDate}</span>
                  </li>
                )}
                {promotionDates.endDate && (
                  <li className="list-group-item">
                    Promotion End Date
                    <span className="float-end">{promotionDates.endDate}</span>
                  </li>
                )}
              </ul>
            </Card>
          </div>
        </div>
        {/* ฟอร์มรีวิว */}
        <div className="row d-flex justify-content-center">
          <div className="col-md-3 center">
          <h3>รีวิวสินค้า</h3>
          <form onSubmit={handleSubmitReview}>
            <div>
              <label>Rating (1-5):</label>
              <input
                type="number"
                value={rating}
                onChange={(e) => setRating(e.target.value)}
                min="1"
                max="5"
                required
              />
            </div>
            <div>
              <label>Comment:</label>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
              />
            </div>
            <button type="submit">Submit Review</button>
          </form>
          </div>

        </div>

        {/* แสดงรีวิว */}
        <div>
      <h3>Reviews</h3>
      <div>
        {reviews.map((review) => (
          <div key={review._id}>
            <strong>{review.user.username}</strong>
            <Rate disabled value={review.rating} />
            <p>{review.comment}</p>
          </div>
        ))}
      </div>

      <h3>Add a Review</h3>
      <Rate value={rating} onChange={setRating} />
      <TextArea
        rows={4}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Write your review here"
      />
      <Button onClick={handleSubmitReview} type="primary">
        Submit Review
      </Button>

       {/* แสดงรีวิว */}
      <List
        dataSource={reviews}
        renderItem={(review) => (
          <List.Item key={review._id}>
            <h3>{review.user.username}</h3>
            <Rate disabled value={review.rating} />
            <p>{review.comment}</p>
          </List.Item>
        )}
      />
        
    </div>

      </div>
      {/* <Footer /> */}
    </>
  );
};

export default SingleProductCard;
